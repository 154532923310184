import styled from "styled-components"

export const StyledWrapper = styled.div`
  min-height: 550px;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.other.gap};
  font-family: "Fira Sans", sans-serif;

  ${({ theme }) => theme.mq.desktop} {
    min-height: 650px;
  }

  background: url(${({ image }) => image.childImageSharp.fluid.src});
  background-color: ${({ theme }) => theme.color.blue};
  background-position: 80% center;
  /* background-blend-mode: luminosity; */
  background-repeat: no-repeat;
  background-size: cover;

  /* -webkit-clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0); */
`
export const StyledHeader = styled.h2`
  font-size: 4rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  /* color: ${({ theme }) => theme.color.yellow}; */
  text-shadow: 2px 2px 2px black;
  ${({ theme }) => theme.mq.desktop} {
    font-size: 6rem;
  }
`

export const StyledSubheader = styled.h3`
  font-size: ${({ theme }) => theme.font.size["30"]};
  position: relative;
  text-align: center;
  font-weight: 300;
  margin-top: 0px;
  color: ${({ theme }) => theme.color.yellow};
  letter-spacing: -1px;
  word-spacing: 15px;
  display: flex;
  justify-content: space-between;
`

export const StyledDecorationLine = styled.div`
  height: 5px;
  width: 80px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.color.yellow};
`
