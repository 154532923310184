import styled from "styled-components"
import TopImg from "../../../../../images/offerTop.png"
import BottomImg from "../../../../../images/offerBot.png"

export const StyledOfferWrapper = styled.section`
  padding: 40px 15px 5px 15px;
  background: url(${TopImg}) 100% -5px no-repeat,
    url(${BottomImg}) 0 100% no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mq.desktop} {
    padding: 55px 15px;
  }
`

export const StyledOfferContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.mq.desktop} {
    width: 80%;
  }
`
export const StyledSubheader = styled.h4`
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size["16"]};
  line-height: 24px;
  margin-top: 25px;
  padding: 0 50px;
  text-align: center;
  width: 100%;

  ${({ theme }) => theme.mq.desktop} {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
`
export const StyledOfferItemWrapper = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
