import React, { useState, useRef, useEffect } from "react"
import Swipe from "react-easy-swipe"
import { useStaticQuery, graphql } from 'gatsby'
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import {
  StyledWrapper,
  StyledHeader,
  StyledItemWrapper,
  StyledPrevItem,
  StyledNextItem,
  StyledFontIcon,
} from "./styled"
import RealizationItem from "../RealizationItem/RealizationItem"

function Realization() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const dotContainer = useRef(null)

  useEffect(() => {
    Array.from(dotContainer.current.children).forEach(item => item.classList.remove('active'));;
    dotContainer.current.children[currentSlide].classList.add('active');
  }, [currentSlide]);

  const content = useStaticQuery(graphql`
  {
    allDatoCmsRealization {
      nodes {
        header
        realizationitem {
          realiztionName
          realizationDescription
          realizationInformation
          realizationImage {
            url
          }
        }
      }
    }
  }
`)

  const data = content.allDatoCmsRealization.nodes[0];

  const {header, realizationitem } = data
  
  const setPrevSlide = () => {
    let slide = currentSlide <= 0 ? realizationitem.length - 1 : currentSlide - 1
    setCurrentSlide(slide)
  }
  const setNextSlide = () => {
    let slide = currentSlide >= realizationitem.length - 1 ? 0 : currentSlide + 1
    setCurrentSlide(slide)
  }
  const setSlide = side => {
    side === "prev" ? setPrevSlide() : setNextSlide()
  }
  const onSwipeRight = () => {
    setPrevSlide()
  }
  const onSwipeLeft = () => {
    setNextSlide()
  }

  return (
    <StyledWrapper id="realization">
      <StyledHeader decoration>{header}</StyledHeader>
      <StyledItemWrapper>
        <Swipe
          onSwipeRight={() => {
            onSwipeRight()
          }}
          onSwipeLeft={() => {
            onSwipeLeft()
          }}
        >
          <RealizationItem slide={realizationitem[currentSlide]} items={realizationitem}  ref={dotContainer}/>
        </Swipe>
      </StyledItemWrapper>


      <StyledPrevItem
        onClick={() => {
          setSlide("prev")
        }}
      >
        <StyledFontIcon icon={faAngleLeft} size="4x" />
      </StyledPrevItem>
      <StyledNextItem
        onClick={() => {
          setSlide("next")
        }}
      >
        <StyledFontIcon icon={faAngleRight} size="4x" />
      </StyledNextItem>
    </StyledWrapper>
  )
}

export default Realization
