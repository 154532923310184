import React from "react"
import {
  StyledWrapper,
  StyledSection,
  StyledParagraph,
  StyledHeader,
  StyledHeaderImg,
} from "./styled"
import { graphql, useStaticQuery } from "gatsby"

function AboutUs() {
  const content = useStaticQuery(graphql`
    {
      file(name: { eq: "underTitleImg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      },
      allDatoCmsAbout {
        nodes {
          header
          description
        }
      }
    }
  `)

  
  const {header, description} = content.allDatoCmsAbout.nodes[0];

  return (
    <StyledWrapper id="aboutUs">
      <StyledSection>
        <StyledHeader>
          {header}
          <StyledHeaderImg
            src={content.file.childImageSharp.fluid.src}
            alt="decoration elements"
          />
        </StyledHeader>

        <StyledParagraph>{description}</StyledParagraph>

        {/* <StyledButton>Poznaj nas lepiej</StyledButton> */}
      </StyledSection>
    </StyledWrapper>
  )
}

export default AboutUs
