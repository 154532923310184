import React from "react"
import Media from 'react-media';
import { StyledWrapper, StyledList, StyledContentWrapper, StyledMapWrapper } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobileAlt, faEnvelopeOpenText, faMapMarkedAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'


const mapStyles = {
  height: '100%'
}

const Contact = () => {
  
  const linkMailAddress = 'biuro@el-mex.pl';
  const linkMailSubject = 'Zapytanie o ofertę';
  const linkMailString = `mailto:${linkMailAddress}?subject=${linkMailSubject}`


  const googleMapsUrl = 'https://maps.google.com/maps';
  const mapQuery = '?q=Cz%C4%99stochowa&t=&z=11&ie=UTF8&iwloc=&output=embed'
  const mapSrc = `${googleMapsUrl}${mapQuery}`;

  const mediaQueries = {
    mobile: "(max-width: 767px)",
    tablet: "(min-width: 769px)",
    desktop: "(min-width: 1024px)",
    huge: "(min-width: 1440px)"
  }

  return (
    <StyledWrapper id="contact">

      <StyledContentWrapper>

        <h3 className="thin">Skontaktuj się z nami</h3>
        <h4>EL-MEX Piotr Palka</h4>
        <h4>Instalacje Elektryczne </h4>
        <StyledList>
          <li>
            <Media queries={mediaQueries}>
              {matches => (
                <>
                {!matches.tablet ? 
                  <>
                    <FontAwesomeIcon icon={faMobileAlt}></FontAwesomeIcon>
                    <span><a href="tel:501-324-602">501-324-602</a></span>
                  </>
                    : 
                  <>
                    <FontAwesomeIcon icon={faMobileAlt}></FontAwesomeIcon>
                    <span>501-324-602</span>  
                  </>
                }
                </>
              )}  
              
            </Media>
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelopeOpenText}></FontAwesomeIcon>
            <span><a href={linkMailString}>biuro@el-mex.pl</a></span>
          </li>
          <li>
            <FontAwesomeIcon icon={faMapMarkedAlt}> </FontAwesomeIcon>
            <span>Częstochowa, ul Piastowska 87</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            <span>
              NIP: 5731511657 <br />
            </span>
          </li>
        </StyledList>

      </StyledContentWrapper>

      <StyledMapWrapper>
      <div className="mapouter" style={mapStyles}>
          <div className="gmap_canvas" style={mapStyles} >
            <iframe title="Google Maps Map" width="100%" height="100%" id="gmap_canvas" src={mapSrc} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
          </div>
        </div>
      </StyledMapWrapper>

    </StyledWrapper>
  )
}

export default Contact
