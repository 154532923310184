import styled from "styled-components"

import LeftImg from "../../../../../images/ShapeFoto.png"
import RightImg from "../../../../../images/ShapeText.png"

export const StyledWrapper = styled.section`
  margin-top: -10px;
  padding: 40px 15px 0 15px;
  margin-bottom: 50px;
  background: url(${RightImg}) 100% 10px no-repeat;
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: center;

  ${({ theme }) => theme.mq.desktop} {
    background-image: url(${LeftImg}), url(${RightImg});
    background-position: -30vw 0, 100% 10px;
    background-size: 100%, 100% calc(100% - 10px);
    background-repeat: no-repeat;
    padding: 20px 75px 0 0;
    justify-content: flex-end;
    margin-top: 0px;
  }
`
export const StyledSection = styled.section`
  margin-top: 20px;
  width: 90%;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;

  ${({ theme }) => theme.mq.desktop} {
    margin-top: 50px;
    width: 30%;
    text-align: right;
  }
`
export const StyledParagraph = styled.p`
  overflow: hidden;
  font-size: ${({ theme }) => theme.font.size["16"]};
  padding-bottom: 50px;

  ${({ theme }) => theme.mq.desktop} {
    margin-top: 55px;
  }
`
export const StyledButton = styled.button`
  display: block;
  background-color: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size["21"]};
  font-family: ${({ theme }) => theme.font.family.fira};
  text-align: center;
  text-transform: uppercase;
  padding: 5px 15px;
  margin-top: 50px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out,
    color 0.1s ease-in-out;

  &:hover {
    background-color: transparent;
    border: 3px solid ${({ theme }) => theme.color.yellow};
    color: ${({ theme }) => theme.color.yellow};
  }

  ${({ theme }) => theme.mq.desktop} {
    width: auto;
  }
`
export const StyledHeader = styled.h3`
  color: ${({ theme }) => theme.color.yellow};
  text-align: center;
  font-size: ${({ theme }) => theme.font.size["40"]};
  text-transform: uppercase;
  position: relative;
  display: block;
  width: 100%;

  ${({ theme }) => theme.mq.desktop} {
    text-align: right;
  }
`
export const StyledHeaderImg = styled.img`
  display: block;
  margin: 20px auto;

  ${({ theme }) => theme.mq.desktop} {
    right: 0;
    margin: 0 auto;
    position: absolute;
    top: calc(100% + 20px);
  }
`
