import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  StyledHeader,
  StyledSubheader,
  StyledWrapper,
  StyledDecorationLine,
} from "./styled"

const HeroImage = () => {
  const content = useStaticQuery(graphql`
    {
      file(name: { regex: "/topBg-light/" }) {
        childImageSharp {
          fluid(
            maxWidth: 1900
            duotone: { highlight: "#3777bc", shadow: "#192550" }
          ) {
            src
            tracedSVG
          }
        }
      },
      allDatoCmsHerosection {
        nodes {
          header
          subheader
        }
      }
    }
  `)


  const { file } = content;
  const {header, subheader} = content.allDatoCmsHerosection.nodes[0];

  return (
    <StyledWrapper image={file}>
      <StyledHeader> {header.toUpperCase()} </StyledHeader>

      <StyledSubheader> {subheader.toUpperCase()} </StyledSubheader>
      <StyledDecorationLine />
    </StyledWrapper>
  )
}


export default HeroImage
