import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/pages/Home/HeroImage/HeroImage"
import Offer from "../components/pages/Home/Offer/Offer"
import AboutUs from "../components/pages/Home/AboutUs/AboutUs"
import Realization from "../components/pages/Home/Realization/Realization"
import Contact from "../components/pages/Home/Contact/Contact"

const keywords = [
  `El-mex`, 
  `Piotr Palka`, 
  `Firma robót elektrycznych`, 
  'częstochowa',
  'pomiary elektryczne',
  'elektryk częstochowa',
  'uszynienia',
  'elektryk śląsk'
];

const IndexPage = () => (
  <Layout >
    <SEO title="Home" keywords={keywords} />

    <HeroImage />
    <Offer />
    <AboutUs />
    <Realization />
    <Contact />
  </Layout>
)

export default IndexPage
