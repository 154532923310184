import React from "react"
import styled, { css } from "styled-components"
import UnderTitleImg from "../../../images/underTitleImg.png"
import PropTypes from "prop-types"

const StyledHeader = styled.h3`
  color: ${({ color, theme }) =>
    color === "blue" ? theme.color.blue : theme.color.yellow};
  text-align: ${({ alignText }) => (alignText ? `${alignText}` : "center")};
  font-size: ${({ theme }) => theme.font.size["36"]};
  text-transform: uppercase;
  position: relative;
  display: block;
  width: 100%;
`
const StyledHeaderImg = styled.img`
  display: block;
  margin: 25px auto;

  ${({ decorationPosition }) =>
    decorationPosition &&
    css`
      margin: 0;
      position: absolute;
      top: calc(100% + 20px);
      ${decorationPosition}: 0;
    `}
`

const Header = ({
  color,
  children,
  decoration,
  alignText,
  decorationPosition,
}) => {
  return (
    <StyledHeader color={color} alignText={alignText}>
      {children}
      {decoration && (
        <StyledHeaderImg
          decorationPosition={decorationPosition}
          src={UnderTitleImg}
          alt="decoration elements"
        />
      )}
    </StyledHeader>
  )
}

Header.propTypes = {
  align: PropTypes.string,
  decoration: PropTypes.bool,
  alignText: PropTypes.string,
  decorationPosition: PropTypes.string,
}

export default Header
