import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import OfferItem from "../OfferItem/OfferItem"
import Header from "../../../atoms/Headers/Headers"
import ReactMarkdown from 'react-markdown'
import {
  StyledOfferItemWrapper,
  StyledOfferWrapper,
  StyledSubheader,
  StyledOfferContainer,
} from "./styled"

const Offer = () => {

  const content = useStaticQuery(graphql`
    {
      allDatoCmsOffer {
        nodes {
          header
          subheader
          tile {
            id
            title
            description
            icon {
              url
            }
          }
        }
      }
    }
  `)  
  
  const { header, subheader, tile } = content.allDatoCmsOffer.nodes[0]
  
  return (
    <StyledOfferWrapper id="offer">
      <StyledOfferContainer>
        
        <Header color="blue" decoration={true}>
          <ReactMarkdown children={header}/>
        </Header>

        <StyledSubheader> 
          <ReactMarkdown children={subheader} /> 
        </StyledSubheader>

        <StyledOfferItemWrapper>
          {tile.map(el => (
            <OfferItem key={el.id} content={el} />
          ))}
        </StyledOfferItemWrapper>

        
      </StyledOfferContainer>
    </StyledOfferWrapper>
  )
}

export default Offer
