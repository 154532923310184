import styled from "styled-components"

import Img from "gatsby-image"
export const StyledWrapper = styled.div`
  display: grid;
  margin-top: 20px;
  gap: 25px 35px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "header"
    "image"
    "dots"
    "infos";
  padding: 0 10px;

  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px auto;
    grid-template-areas:
      "image header"
      "image infos";
  }
`

export const StyledImage = styled(Img)`
  grid-area: image;
`
export const StyledImageNative = styled.img`
  grid-area: image;
  height: 200px;
  object-fit: cover;
  width: 100%;
`


export const StyledInfoWrapper = styled.div`
  grid-area: infos;
`
export const StyledHeader = styled.h3`
  grid-area: header;
  color: ${({ theme }) => theme.color.blue};
  font-size: ${({ theme }) => theme.font.size["26"]};
  padding: 0;
  margin-bottom: 0;
  text-align: center;

  ${({ theme }) => theme.mq.desktop} {
    text-align: left;
  }
`
export const StyledDescription = styled.p`
  font-weight: normal;
  padding: 0;
  margin-bottom: 35px;
`
export const StyledList = styled.ul`
  list-style: none;
  white-space: break-spaces;
  padding: 0;
`
export const StyledListItem = styled.li`
  position: relative;
  margin-left: 15px;

  :before {
    display: block;
    content: "";
    position: absolute;
    left: -28px;
    top: 3px;
    border-radius: 3px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 14px solid ${({ theme }) => theme.color.yellow};
    border-bottom: 7px solid transparent;
  }
`
