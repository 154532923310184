import styled from "styled-components"

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;

  &:nth-last-child(2) {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  ${({ theme }) => theme.mq.desktop} {
    width: 33%;
  }
`

export const StyledIcon = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 80px;
`

export const StyledTitle = styled.h4`
  font-size: ${({ theme }) => theme.font.size["21"]};
  color: ${({ theme }) => theme.color.blue};
  text-transform: uppercase;
  text-align: center;
  margin-top: 35px;
  max-width: 300px;
`

export const StyledDescription = styled.p`
  font-size: ${({ theme }) => theme.font.size["16"]};
  font-family: ${({ theme }) => theme.font.family.fira};
  text-align: center;
  font-weight: 400;
  margin-top: 15px;
  max-width: 300px;
`
