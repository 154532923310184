import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Header from "../../../../atoms/Headers/Headers"
import background from '../../../../../images/realizacjeBg2.png'

export const StyledWrapper = styled.section`
    position: relative;
    margin-top: 20px;
    font-size: ${ ({theme}) => theme.font.size['16'] };
    padding-bottom: 20px;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: 0% 20%;
      
    
    ${({theme}) => theme.mq.desktop} {
      padding-bottom: 100px;
    } 
`;
export const StyledHeader = styled(Header)`
    
    font-size: ${({ theme }) => theme.font.size['40']};

   ${({theme}) => theme.mq.desktop} {
    text-align: left;
  }
`
export const StyledItemWrapper = styled.div`
  width: 95vw;
  margin: 0 auto;

  ${({theme}) => theme.mq.desktop } {
    max-width: 60vw;
  };
`
export const StyledPrevItem = styled.div`
  position: absolute;
  height: 50px;
  top:45%;
  left: 10%;
  display: none;
  
  a{    
    display: block;
    width: 20px;
    height: 20px;
  }
  
  ${({theme}) => theme.mq.desktop} {
    display: block;
  }
  
`
export const StyledNextItem = styled.div`
  position: absolute;
  top: 45%;
  height: 50px;
  right: 10%;
  display: none;
  
  a {
    
    display: block;
    width: 20px;
    height: 20px;
  }
  
  ${({theme}) => theme.mq.desktop} {
    display: block;
  }
  
`
export const StyledFontIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color.yellow};
  cursor: pointer;
  transition: color .3s ease-in-out;
  
  &:hover { 
    color: ${({ theme }) => theme.color.yellowLighten};
  }
`
export const StyledSliderDotWrapper = styled.div`
  grid-area: dots;
  width: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }

`
export const StyledSliderDot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: lightgray;

  &.active {
    background-color:  ${({theme}) => theme.color.yellow}
  }
`
