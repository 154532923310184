import React from "react"
import PropTypes from "prop-types";
import {StyledWrapper, StyledIcon, StyledTitle, StyledDescription } from './styled'

const OfferItem = (props) => {

  const { icon, title, description } = props.content
  
  return (
    <StyledWrapper>
      <StyledIcon src={icon.url} alt=""/>
      <StyledTitle> {title} </StyledTitle>
      <StyledDescription> {description} </StyledDescription>
    </StyledWrapper>
  )
}

OfferItem.propTypes = {
  content: PropTypes.object.isRequired,

}

export default OfferItem
