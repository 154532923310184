import styled from "styled-components"

export const StyledWrapper = styled.section`

  margin-top: 0px; 
  margin-bottom: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 0;

  
  ${({theme}) => theme.mq.desktop} {
    align-items: flex-start;
    text-align: left;
    flex-direction: row;
    margin-bottom: 50px;
  }

  /* &::after {
    content: none;
    display: block;
    position: absolute;
    background-image: url('${({mapImage}) => mapImage}');
    background-size: cover;
    background-repeat: no-repeat;
    width: 60vw;
    height: 400px;
    right: 0;
    top: -40px;

    ${({theme}) => theme.mq.desktop} {
      content: '';
    }
  }*/

  

  h3 {
    position: relative;
    font-size: ${({ theme }) => theme.font.size["21"]};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    text-transform: uppercase;
    text-align: inherit;
    margin-bottom: 30px;

    &.thin {
      font-weight: normal;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: -15px;
      width: 100px;
      height: 2px;
      background-color: white;
    }
  }

  h4 {
    font-size: ${({ theme }) => theme.font.size["21"]};
    color: ${({ theme }) => theme.color.white};
    text-align: left;
    font-weight: bold;
    text-align: inherit;

    &:first-of-type {
      margin-bottom: 0;
    }
  }
`

export const StyledHeaderWrapper = styled.div`
    width: 100%;  
    padding-left: 45px;
    padding-top: 25px;
    margin-bottom: 45px;
    background-color: ${({theme})=> theme.color.blue};
    border-bottom: 25px solid white;
    color: black;
`;

export const StyledContentWrapper = styled.div`
    width: 100%; 
    background: linear-gradient(45deg, hsl(211deg 55% 35%), hsl(211deg 55% 52%)); 
    padding-top: 35px;
    padding-left: 45px;
    padding-bottom: 15px;
`;

export const StyledList = styled.ul`
  margin-top: 40px;
  margin-left: 0;
  font-size: ${({ theme }) => theme.font.size["16"]};
  color: ${({ theme }) => theme.color.white};
  list-style: none;
  display: block;
  max-width: 270px;
  
  ${({theme}) => theme.mq.desktop} {
    margin-left: 0;
    left: 0%;
    transform: none;
  }

  svg {
    width: 16px !important;
    object-fit: contain;
  }

  li {
    display: block;
    text-align: left;
    

    &:last-of-type {
      display: flex;
      align-items: center;
      /* justify-content: center;        */

      span {
        display: block;
      }
    }

    span {
      margin-left: 20px;

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
`
export const StyledMapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  clip-path: unset;

  ${({theme}) => theme.mq.desktop} {
    position: absolute;
    width: 80%;
    top: -20px;
    right: 0;
    height: 370px;
    clip-path: polygon(40% 0%,100% 0%,100% 100%,20% 100%);
  }

`