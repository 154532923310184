import React from "react"
import {
  StyledWrapper,
  StyledInfoWrapper,
  StyledHeader,
  StyledDescription,
  StyledImageNative,
  StyledList
} from "./styled/RealizationItem.styled"

import { StyledSliderDot, StyledSliderDotWrapper } from '../Realization/styled'


const RealizationItem = React.forwardRef( ({ slide, items }, ref) =>  {
    const { realiztionName, realizationDescription, realizationInformation, realizationImage } = slide
  
    

    return (
      <StyledWrapper>
        {/* <StyledImage fluid={imageQuery.file.childImageSharp.fluid} /> */}
        <StyledImageNative loading="lazy" alt={realiztionName} src={realizationImage.url}/>
  
        <StyledSliderDotWrapper ref={ref}>  
          { items.map((_,idx) => <StyledSliderDot key={idx}></StyledSliderDot>) }
        </StyledSliderDotWrapper>
  
        <StyledHeader>{realiztionName}</StyledHeader>
  
        <StyledInfoWrapper>
          <StyledDescription>{realizationDescription}</StyledDescription>
          { realizationInformation ? <StyledList>{realizationInformation}</StyledList> : null}
          
          {/* <StyledList>
            {list.listItems.map((item, idx) => {
              return <StyledListItem key={idx}> {item} </StyledListItem>
            })}
          </StyledList> */}
        </StyledInfoWrapper>
      </StyledWrapper>
    )
  }
) ;

// RealizationItem.propTypes = {
//   slide: PropTypes.object.isRequired,
// }

export default RealizationItem
